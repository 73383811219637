import { mapState } from 'vuex';
import { getModules } from '@/api';
import ModuleCard from './Card/Card.vue';

export default {
    name: 'module-list',
    components: {
        ModuleCard,
    },
    props: {
        modules: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            excluded: ['admin', 'dictionary', 'minister-module', 'sit-center'],
            availableModules: [],

        };
    },
    computed: {
        moduleList({ modules, excluded, availableModulesNames }) {
            const filtered = modules.filter(({ id }) => !excluded.includes(id));
            // return filtered;
            return filtered?.map((module) => ({
                ...module,
                notWorking: !availableModulesNames.includes(module.id),
                disabled: this.checkDisabled(module.disabled),
            }));
        },
        ...mapState([
            'modulesLinks',
        ]),
        availableModulesNames({ availableModules }) {
            return availableModules.map(({ name }) => name);
        },
    },
    methods: {
        // getImgUrlIcons(imageName) {
        //     try {
        //         const images = require.context('@/assets/img/icons/', false, /\.svg/);
        //         return images(`./${imageName}.svg`);
        //     } catch (e) {
        //         return '';
        //     }
        // },
        // startAnimation() {
        //     this.modules.forEach((obj, index) => {
        //         setTimeout(() => {
        //             this.$set(obj, 'animated', true);
        //         }, 30 * (index + 1));
        //     });
        // },
        checkDisabled(disabledField = false) {
            if (disabledField && typeof disabledField === 'function') return disabledField(this);
            return disabledField;
        },
        async getAvailableModules() {
            try {
                this.loading = true;
                const { data } = await getModules();
                this.$set(this, 'availableModules', data);
            } catch (e) {
                console.error(e);
            } finally {
                this.loading = false;
            }
        },
    },
    async created() {
        await this.getAvailableModules();
        // setTimeout(() => {
        //     this.startAnimation();
        // }, 1000);
    },
};
