import { navigateToUrl } from 'single-spa';
import EventBus from '@/eventBus';
import modules from '@/modules';
import mapModule from '@eputs/map-module';
import mapButtons from '@eputs/map-buttons';
import {
    mapTrafficSimple,
} from '@eputs/map-buttons/types';
// import ModuleT from '@eputs/map-buttons/types'; // eslint-disable-line

import ModuleList from './ModuleList/ModuleList.vue';

// console.log(ModuleT);
// console.log(mapTrafficSimple);

export default {
    name: 'StartPage',
    components: {
        mapModule,
        mapButtons,
        ModuleList,
    },
    data() {
        return {
            loading: true,
            modules,
            buttons: [
                {
                    name: mapTrafficSimple,
                    params: {
                        visible: true,
                        access: true,
                    },
                },
            ],
            map: null,
        };
    },
    computed: {

    },
    methods: {
        onMapLoaded(map) {
            this.map = map;
        },
    },
    created() {
        // this.$store.commit('setModulesLinks', this);
        EventBus.$on('map/main-map/loaded', this.onMapLoaded);
        EventBus.$emit('set-headerMenu/list', []);
        EventBus.$emit('set-header/moduleId', null);
        this.$store.commit('setModuleId', null);
    },
    beforeMount() {
        this.$store.commit('setMapStyles', localStorage.getItem('mapStylesCurrent') || 'default');
    },
    mounted() {
        this.$nextTick(() => {
            if (this.$checkPermission('eputs_minister_access')) {
                navigateToUrl('/module/minister/map');
            } else {
                this.loading = false;
            }
        });
    },
    beforeDestroy() {
        EventBus.$off('map/main-map/loaded', this.onMapLoaded);
        if (this.map) this.map.remove();
    },
};
